import { useState, useEffect } from 'react';
import { useParams ,useNavigate} from 'react-router-dom';
import {db} from './firebaseconfig.js';
import {doc, getDoc} from "firebase/firestore"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import imgloader from "./assets/loadingscreen.png"



function ProductDetail() {
    const [userInfo, setUserInfo] = useState([]);
    const { product } =  useParams()
    const { id } = useParams()
    const [categorieInfo, setCategoreInfo] = useState([]);

    const imgloadersrc = imgloader

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
      useEffect(() =>{
        const catRef = doc(db, "menu", id)
        
        getDoc(catRef).then((doc) =>{
        setCategoreInfo(doc.data(), doc.id)
        })
        
      }, [])


    useEffect(() =>{
        const docRef = doc(db, "menu", id, "products", product)
        
        getDoc(docRef).then((doc) =>{
        setUserInfo(doc.data(), doc.id)
        })
        
      }, [])

      const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
	}

    return(
        <div>
        <div className=" sticky top-0 text-lg p-4 bg-black border-b-2 border-yellow-500 font-bold shadow-2xl">
            <img className="h-6 w-6 absolute left-2 top-0 m-5" onClick={goBack} src="https://firebasestorage.googleapis.com/v0/b/babylonshishabar-2d5ff.appspot.com/o/icons8-back-100.png?alt=media&token=6a3fa38b-7bdf-4ab3-b704-b948c72c6faf&_gl=1*1f3lq0m*_ga*MTAxODAzMzUzOC4xNjg1Nzg0OTU5*_ga_CW55HF8NVT*MTY4NTg0NjkyNC40LjEuMTY4NTg0NjkzOC4wLjAuMA.."></img>
            
            <div className="flex justify-center">{categorieInfo.name}</div>
            </div>
        <div className=""><img className="" src={userInfo.img}></img></div>

        <div className="flex justify-between p-3 bg-yellow-500">
        <div className="font-bold  text-xl">{userInfo.name || <Skeleton />}</div>
        <div className=" font-bold text-black text-xl">{userInfo.preis || <Skeleton />}</div>
        
        </div>

        <div className="  bg-zinc-900 h-screen p-6">{userInfo.list || <Skeleton count={4}/>}</div>

        </div>
    )
}
export default ProductDetail;