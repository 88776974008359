import { db } from "./firebaseconfig.js"
import { useEffect, useState} from "react";
import { useParams, Link, useNavigate} from 'react-router-dom';
import {collection, getDocs} from "firebase/firestore"
import 'react-loading-skeleton/dist/skeleton.css'
import imgloader from "./assets/loadingscreen.png"
import Skeleton from "react-loading-skeleton";
import {doc, getDoc} from "firebase/firestore"


function ProductInfo() {

const {id} = useParams()
const [items, setItems] = useState([]);
const productsRef = collection(db, "menu", id, "products")
const [isLoading, setLoading] = useState(true)
const [categorieInfo, setCategoreInfo] = useState([]);

useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() =>{
    const catRef = doc(db, "menu", id)
    
    getDoc(catRef).then((doc) =>{
    setCategoreInfo(doc.data(), doc.id)
    })
    
  }, [])

useEffect(() =>{

    const getItems = async () => {
      const data = await getDocs(productsRef);
      setItems(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
    }
    setLoading(false)
    getItems()
  }, [])

  const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
	}

    return(
        <div className="bg-zinc-900">
        <div className=" sticky top-0 text-lg p-4 bg-black border-b-2 border-yellow-500 font-bold shadow-2xl">
            <img className="h-6 w-6 absolute left-2 top-0 m-5" onClick={goBack} src="https://firebasestorage.googleapis.com/v0/b/babylonshishabar-2d5ff.appspot.com/o/icons8-back-100.png?alt=media&token=6a3fa38b-7bdf-4ab3-b704-b948c72c6faf&_gl=1*1f3lq0m*_ga*MTAxODAzMzUzOC4xNjg1Nzg0OTU5*_ga_CW55HF8NVT*MTY4NTg0NjkyNC40LjEuMTY4NTg0NjkzOC4wLjAuMA.."></img>
            
            <div className="flex justify-center">{categorieInfo.name || <Skeleton/> }</div>
            </div>
        <div className="md:grid md:grid-cols-2 pb-10">
       
         {items.map((item) =>{
                return <div className="m-5 bg-zinc-800  rounded-xl shadow-2xl">
                <Link to={`/${id}/${item.id}`}>
                
                <img className="rounded-t-xl" src={item.img}></img>
                <div className="flex justify-between p-2 border-t-2 border-yellow-400">
                <div className="font-bold">{item.name || <Skeleton/>}</div>
                <div className="font-bold text-lg text-yellow-400">{item.preis || <Skeleton/>}</div>
                </div>
                </Link>
                </div>
            })}

        </div>
        
        </div>
    )
}
export default ProductInfo;